import { FileTypeRadioButton } from "./FileTypeRadioButton"
import './../index.css'
import React from "react";
import { EnvironmentVariables } from "../EnvironmentVariables";


export const UploadForm: React.FC = () => {
  const [statusMessage, setStatusMessage] = React.useState<{
    state: 'SUCCESS' | 'FAIL', message: string} | null>(null);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileType, setSelectedFileType] = React.useState<string | null>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [fileInputKey, setFileInputKey] = React.useState<number>(Date.now());

  const fileTypeOptions = [
    { fileType: 'asn-meter', label: "ASN Meter" },
    { fileType: 'asn-enrichment', label: "ASN Enrichment" },
    { fileType: 'wtbi', label: "WTBI" },
  ];

  const displayStatusMessage = React.useMemo(() => (
    <>
        {statusMessage !== null && <div className='pb-2 max-w-full'>
            <label className={`inline-block mr-1 pt-5 ${statusMessage.state === 'SUCCESS' ? 'text-feedback-green' : 'text-feedback-red'}`}>{statusMessage.message}</label>
        </div>}
    </>
  ), [statusMessage])

  const handleFileSubmit = async () => {
    setSubmitting(true);
    setStatusMessage(null);
    if (!selectedFile || selectedFile.name === '' || selectedFileType === '' || selectedFileType === null) {
        setStatusMessage({state: 'FAIL', message: 'Please upload a file and select a file type'});
        return;
    }

    const uploadFile = async () => {
      console.log('GETTING PRESIGNED URL');
      const presignedUrlResponse = await fetch(`${EnvironmentVariables.ApiUrl}/presigned-post`,
          {
              method: 'POST',
              body: JSON.stringify({
                  file_type: selectedFileType,
                  file_name: selectedFile.name
              }),
              credentials: 'include'
      }
      )

      if (!presignedUrlResponse.ok) return;

      const presignedUrl = await presignedUrlResponse.json();

      const s3Form = new FormData();
      for (const key in presignedUrl.fields) {
          s3Form.append(key, presignedUrl.fields[key]);
      }
      s3Form.append('file', selectedFile);

      console.log('POSTING TO PRESIGNED URL');
      return await fetch(presignedUrl.url, {
          method: 'POST',
          body: s3Form
      })
    }

    const postResponse = await uploadFile();

    setSubmitting(false);
    if (postResponse && postResponse.ok) {
        setSelectedFileType(null);
        setSelectedFile(null);
        setFileInputKey(Date.now());
        setStatusMessage({state: 'SUCCESS', message: `File ${selectedFile.name} has been submitted for validation and processing!`});
        return
    }
    setStatusMessage({state: 'FAIL', message: 'Upload failed, please try again later.'})
  }

  const isButtonDisabled = React.useMemo(() => {
    return selectedFile === null || selectedFileType === null || submitting;
  }, [selectedFile, selectedFileType, submitting])

  const handleFileInput = (input: React.FormEvent<HTMLInputElement>) => {
    const files: File[] = (input.target as any).files;
    if (files.length === 0) return;
    setSelectedFile(files[0])
  }

  const disabledClassStyling = "bg-secondary-deep-blue-80 pointer-events-none cursor-not-allowed text-secondary-deep-blue-55";
  const activeClassStyling = "bg-interactive-coral hover:bg-solid-bold-green cursor-pointer text-solid-deep-blue";


  return (
    <section className="flex flex-col mt-6 mb-80 ml-14 max-w-full w-[100%] max-md:mb-10 max-md:ml-2.5">
      {displayStatusMessage}
      <label className="text-sm leading-5 text-slate-950">
      Select file type:
      </label>
      {fileTypeOptions.map(fileTypeInformation => (
        <FileTypeRadioButton
            key={fileTypeInformation.fileType}
            label={fileTypeInformation.label}
            fileType={fileTypeInformation.fileType}
            selectedFileType={selectedFileType}
            handleFileTypeSelected={(fileType: string) => {setSelectedFileType(fileType)}}
        />
      ))}
      <input
          key={fileInputKey}
          onInput={handleFileInput}
          name="fileName"
          type="file"
          className="flex flex-col w-[250px] justify-center mt-6 text-sm font-bold leading-5 text-cyan-700 rounded border border-cyan-700 border-solid"/>
      <button
          onClick={handleFileSubmit}
          disabled={isButtonDisabled}
          type="submit"
          className={`inline-block w-fit px-2 py-1.5 mt-8 text-sm font-bold leading-5 whitespace-nowrap rounded max-md:px-5 ${isButtonDisabled ? disabledClassStyling : activeClassStyling}`}
          >
          <span>Submit</span>
          <img
              loading="lazy"
              src={`/buttonIconActive.png`}
              alt="Button Icon"
              className="inline-block w-5 pb-1 pl-3"
              />
      </button>
  </section>
  )
}
