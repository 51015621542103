import React from 'react';
import './index.css'
import { EnvironmentVariables } from './EnvironmentVariables';
import { UploadForm } from './components/UploadForm';

function App() {
    const [userInfo, setUserInfo] = React.useState<object | null>(null);

    const getUserInfo: () => Promise<void> = async () => {
        console.log('Getting user info')
        const userInfoResponse = await fetch(`${EnvironmentVariables.ApiUrl}/user-info`, {
            method: 'GET',
            headers: {
                'Content-type' : 'application/json'
            },
            credentials: 'include'
        })
        console.log(`USER INFO RESPONSE: ${JSON.stringify(userInfoResponse)}`)
        if (!userInfoResponse.ok) {
            console.log('HANDLING FAIL')
            if ([401, 403].includes(userInfoResponse.status)) {
                window.location.href = EnvironmentVariables.AuthUrl
            }
            return
        }
        const userInfoJson = await userInfoResponse.json()
        console.log(`USER INFO JSON: ${JSON.stringify(userInfoJson)}`)
        setUserInfo(userInfoJson)
    }

    React.useEffect(() => {
        getUserInfo()
    }, [])

    return (
        <div>
            <div className="flex flex-col w-full text-xl tracking-normal leading-5 whitespace-nowrap bg-stone-100 border-slate-800 text-slate-950 max-md:max-w-full">
                <header className="flex gap-5 px-4 py-1.5 bg-stone-100 border-slate-800 max-md:flex-wrap">
                    <img
                        src="/siemensLogoWhiteBanner.png"
                        alt="Logo"
                        className='h-7 mt-7 col-start-1 col-end-4'
                    />
                    <h1 className="flex-1 text-ellipsis max-md:max-w-full" style={{alignSelf: "end"}}>Asset Management File Portal</h1>
                </header>
            </div>
            <div className='border-b-[1px] border-secondary-deep-blue-80 pb-4'>
            </div>
            {userInfo && <UploadForm />}
        </div>
    )
}

export default App
