type FileTypeRadioButtonProps = {
  label: string;
  fileType: string;
  selectedFileType: string | null;
  handleFileTypeSelected: (fileType: string) => void;
};

export const FileTypeRadioButton: React.FC<FileTypeRadioButtonProps> = ({ fileType, label, handleFileTypeSelected, selectedFileType }) => (
  <div className="w-80 inline-block">
      <input onClick={() => handleFileTypeSelected(fileType)} type="radio" id={fileType} name="fileType" value={fileType} readOnly checked={fileType === selectedFileType}
          className={`translate-y-5 w-4 h-4 border-[2px] rounded-full bg-interactive-bold-green-db-12 border-secondary-deep-blue-30 hover:border-solid-bold-green before:w-2 before:h-2 before:rounded-full before:scale-0 before:shadow-radio-button checked:before:scale-[1px] checked:border-solid-bold-green`} />
      <label htmlFor={fileType} className="ml-7">{label}</label>
  </div>
);
